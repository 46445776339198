import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import "./App.css";
import NavBar from "./components/NavBar";
import ClientInvoiceReport from "./pages/ClientInvoiceReport";
import Footer from "./components/Footer";

const useStyle = makeStyles((theme)=>({
  container: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:0,
  }
}));

function App() {

  const classes = useStyle();

	return (
		<Router className="App">
			<NavBar />
			<Container className={classes.container} >
				<Switch>
					<Route path="/">
						<ClientInvoiceReport />
					</Route>
				</Switch>
			</Container>
			<Footer/>
		</Router>
	);
}

export default App;
