import React, { useRef, useState } from "react";
import {
	MenuItem,
	Button,
	Grid,
    TextField, Typography, Snackbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const listaTipoDocumento = [
    { id: 'BBV', nombre: 'Boleta de Venta Electrónica' },
    { id: 'FFV', nombre: 'Factura de Venta Electrónica' },
    { id: 'NC', nombre: 'Nota de Credito Electrónica' },
];  

const portApi = {
    "20604917787": 3001, //Terracom
    "20601255601": 3002,//Codasur
    "20455278326": 3003, //Comsurpe
    "10409367050": 3004, //Infinity
    "20606018526": 3005,//Zoe
    "20490946471": 3006,//Wayo
    "20606474564": 3008, //Teracomsac
    "20605472096": 3009,//Malaga    
    "20604348537": 3010,// Abundancia
    "10431456121": 3011, //JNL
    "15604611714":3012,//Telemundo
    "10436773884": 3014,// Angelica
    "10481524321": 3013 //Angiela
}
const URL_BASE_API = "https://patrisyo.com";

function FormInvoice() {
	const rucEmpresa = useRef();
    const docCliente = useRef();
    const serieDoc = useRef();
    const numeroDoc = useRef();
    const [tipoDoc, settipoDoc] = useState('BBV');
    const [open, setOpen] = React.useState(false);
    let mensaje = "";

    const showMessage = (m) => {
        mensaje = m;
        setOpen(true);        
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
  
    const downloadDocument = () => {
        const serie = serieDoc.current.value;
        const numero = numeroDoc.current.value;
        const cliente = docCliente.current.value;        

        if (!portApi[rucEmpresa.current.value]) {
            showMessage("Numero de RUC incorrecto.");
            return;
        }

        //Validate data
        if (serie !== "" && numero !== "" && cliente !== "" && portApi[rucEmpresa.current.value]) {
            const port = portApi[rucEmpresa.current.value];
            const urlPdf = `${URL_BASE_API}:${port}/api/impresion/comprobante/${tipoDoc}/${serie}/${numero}/${cliente}/1`
            window.open(urlPdf,"_blank");
        } else {
            showMessage("Complete todos los campos");
            console.error("Error, complete all fields");
        }
    };

	return (
		
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5">Buscar Comprobante</Typography>
            </Grid>
            
            <Grid item xs={12} sm={12}>
                <TextField
                    required
                    id="ruc"
                    label="RUC"
                    variant="outlined"
                    helperText="Ingrese RUC de la empresa"
                    inputRef={rucEmpresa}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={12} sm={12}>						
                <TextField
                    required
                    id="doccliente"
                    label="Doc. Cliente"
                    variant="outlined"
                    helperText="Ingrese su documento de identidad (DNI. RUC, carnet de extranjeria) ."
                    inputRef={docCliente}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={12} sm={12}>

                <TextField
                    id="tipodocumento"
                    fullWidth={true}
                    select
                    label="Tipo Documento"
                    helperText="Seleccione tipo de documento."
                    variant="outlined"
                    value={tipoDoc}
                    onChange={(e)=>{ settipoDoc(e.target.value)}}
                >
                    {listaTipoDocumento.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.nombre}
                        </MenuItem>
                    ))}
                </TextField>

            </Grid>
            <Grid item xs={6} sm={6}>
                <TextField
                    required
                    id="serie"
                    label="Serie"
                    variant="outlined"
                    helperText="Serie del documento."
                    inputRef={serieDoc}
                    fullWidth={true}
                />
                
            </Grid>
            <Grid item xs={6} sm={6}>
                <TextField
                    required
                    id="numero"
                    label="Numero"
                    variant="outlined"
                    helperText="Numero del documento."
                    inputRef={numeroDoc}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Button 
                    variant="contained" 
                    size="large" 
                    color="primary" 
                    fullWidth={true} 
                    onClick={downloadDocument}
                >
                    Buscar
                </Button>
            </Grid>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    Complete todos los campos o Ruc incorrecto.
                </Alert>
            </Snackbar>
        </Grid>			
                
          
	);
}

export default FormInvoice;
