import React from "react";
import { GiDuck } from "react-icons/gi";
import { MdMenu } from "react-icons/md";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
        flexGrow: 0,
	},
	title: {
		flexGrow: 1,
        color:'blue'
	},
    navbar: {
        backgroundColor: 'white',
    },
    link: {
        flexGrow:1,
        marginRight: 5,
        marginLeft: 5,
        width: '80%',
       
    },
    itemlink:{
        marginRight: 5,
        marginLeft: 5,
    }
}));

function NavBar() {
	const classes = useStyles();

	return (
		<AppBar className={classes.navbar} position="fixed" color="inherit" elevation={0}>
			<Toolbar>
				<Link to="section_home" smooth={true}>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="logo"
						style={{fontSize:30, margin:0}}
					>
						<GiDuck color="blue" />
						
					</IconButton>
				</Link>
				<Typography variant="h5" className={classes.title}>
					Patrisyo
				</Typography>
                {/*<div className={classes.link}>
                    <Link href="#" onClick={(e)=>{e.preventDefault()}} className={classes.itemlink}>
                        Sobre Nosotros
                    </Link>
                    <Link href="#" onClick={(e)=>{e.preventDefault()}} className={classes.itemlink}>
                        Servicios
                    </Link>
    </div>*/}
                
				<IconButton
					edge="start"			
					color="inherit"
					aria-label="menu"
				>
					<MdMenu />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

export default NavBar;
