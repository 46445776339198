import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import FormInvoice from '../components/FormInvoice';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-scroll'; 

import imageInvoice from '../assets/invoice-img.jpg';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyle = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
    },
    img: {
        width: '80%'
    },
    title: {
        marginBottom: 10,
        marginTop: '5%',
    },
    imgContainer: {
        textAlign: 'center'
    },
    info: {
        height: window.innerHeight,
        paddingTop: '10%'
    },
    btnConsultar: {
        marginTop: '5%',
    },
    formContainer: {
        marginTop: '5%',
        height: window.innerHeight,
    },
}));

function ClientInvoiceReport() {

    const classes = useStyle();

    return (
        <div className={classes.root}>
            <Grid id="section_home" container spacing={2} wrap="wrap-reverse" className={classes.info}>
                <Grid item xs={12} sm={6} >
                    <ThemeProvider theme={theme}>
                        <Typography variant="h3" className={classes.title}  align="justify">
                            Consulte sus comprobantes electrónicos    
                        </Typography>   
                        <Typography variant="h6" align="justify"> Aqui puede descargar sus comprobantes electrónicos como beletas, facturas y notas de credito en formato PDF y XML.</Typography>       
                        <Button variant="outlined" size="large" color="primary" className={classes.btnConsultar}>                            
                            <Link to="section_form" smooth={true}>Consultar</Link>
                        </Button>  
                    </ThemeProvider>                                    
                </Grid>
                <Grid item xs={12} sm={6} className={classes.imgContainer}>
                    <img
                        src={imageInvoice}
                        alt="Imagen de fondo"
                        className={classes.img}
                    />
                </Grid>
                
            </Grid>  
            <Grid id="section_form" container direction="row" justify="center" alignItems="center" className={classes.formContainer}>
                <Grid item style={{maxWidth: 450}}>
                    <FormInvoice />
                </Grid>               
            </Grid> 
        </div>
                                 
    )
}

export default ClientInvoiceReport
