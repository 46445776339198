import React from 'react';
import { Container, Grid, Typography,IconButton, Divider } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FaFacebookSquare, FaWhatsappSquare, FaTelegram } from 'react-icons/fa'
const useStyles = makeStyles((theme) => ({
    
	root: {
        flexGrow:1,
		width: '100%',
        backgroundColor: 'rgb(22, 42, 65)',
        margin:0,
        maxWidth: '100%',
        color: 'white',
        marginTop:'5%'
	},
    socialicon: {
        color:'white'
    }
}));

function Footer() {

    const classes = useStyles();
    return (
        
            <Container className={classes.root}>
                <Grid container spacing={4}> 
                    <Grid item >
                        <Typography variant="h5">Contactanos</Typography>
                        <Divider light={false} style={{color: 'white'}}/>
                        <IconButton aria-label="delete" className={classes.socialicon} fontSize="large">
                            <FaFacebookSquare  />
                        </IconButton>
                        <IconButton aria-label="delete" className={classes.socialicon} fontSize="large">
                            <FaWhatsappSquare  />
                        </IconButton>
                        <IconButton aria-label="delete" className={classes.socialicon} fontSize="large">
                            <FaTelegram  />
                        </IconButton>
                    </Grid>
                    <Grid item>

                    </Grid>
                    <Grid item xs={12} sm={12} style={{ flexDirection:'row', alignItems:'center', justifyContent:'space-between', display:'flex'}}>
                        <a href='https://www.freepik.com/vectors/work'>Work vector created by www.freepik.com</a>
                        
                        <Typography variant={'caption'} style={{color: 'gray'}}>Developed by cav95</Typography>
                    </Grid>

                </Grid>


            </Container>

    )
}

export default Footer   
